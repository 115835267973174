import {useDispatch, useSelector} from "react-redux";
import {types} from "../../../actions/snackbar";
import {useEffect} from "react";

const CustomSnackbar = () => {

    const {open, title} = useSelector(state => state.snackbar)
    const dispatch = useDispatch()

    useEffect(() => {
        setTimeout(() => {
            dispatch({type:types.SET_SNACKBAR, payload: {title: title, open: false}})
        }, 5000)
    }, [open])

    return (
        <div className={open ? "snackbar snackbar--active" : "snackbar"}>
            <div className="snackbar__content">
                <div className="snackbar__icon">
                    <img src="/files/images/svg/snackbar-ico.svg" alt=""/>
                </div>
                <div className="snackbar__text">{title}</div>
                <div className="snackbar__close">
                    <button className="snackbar__button" aria-label={'Snackbar close'} type="button" onClick={() => dispatch({type:types.SET_SNACKBAR, payload: {title: title, open: false}})}>
                        <span className="snackbar__label">
                            <img src="/files/images/svg/snackbar-close.svg" alt=""/>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CustomSnackbar